import React from 'react';
import Map from './Map';

const Location = () => {
    return (
        <div className="location_wrapper">
            <Map />
            <div className="location_tag">
                <div>Маршрути рейдів куренів “Негуса” і “Дороша”</div>
            </div>
        </div>
    );
};

export default Location;