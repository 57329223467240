import React, { useState } from "react";
import { ReactReader } from "react-reader";
import book from "../../resources/book.epub";
import close from "../../resources/images/icons/close.svg";

const Reader = (props) => {
  const [loc, setLoc] = useState('epubcfi(/6/4[title.xhtml]!/4[title]/2[_idContainer000]/2[_idParaDest-1]/2/1:0)');
  const onLocChange = location => {
    console.log(location);
    setLoc(location);
  }
  return (
    <div className='popup'>
      <div className='popup_inner'>
        <div style={{ position: "relative", height: "100%" }}>
          {" "}
          <ReactReader
            url={book}
            title={"У лабетах долі"}
            location={loc}
            locationChanged={onLocChange}
          />
        </div>
        <button className="close_popup" onClick={props.toggle}><img src={close} alt="Close" width="20" height="20" /></button>
      </div>
    </div>
  );
};

export default Reader;