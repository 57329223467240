import React, { useState } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker,
  InfoWindow
} from "react-google-maps";

import explosion from '../../resources/images/icons/explosion.png'

const Map = () => {
  const path = [
    { lat: 50.623457, lng: 27.165031 },
    { lat: 50.712607, lng: 28.003514 },
    { lat: 50.389172, lng: 28.276884 },
    { lat: 49.941362, lng: 28.606054 },
    { lat: 49.729034, lng: 29.213017 },
    { lat: 49.723258, lng: 29.665011 },
    { lat: 49.699448, lng: 29.964024 },
    { lat: 49.792569, lng: 30.075341 },
    { lat: 49.980928, lng: 29.828598 },
    { lat: 49.879526, lng: 29.291191 },
    { lat: 49.735708, lng: 28.838346 },
    { lat: 49.825988, lng: 28.551620 },
    { lat: 50.047994, lng: 28.099251 },
    { lat: 50.287817, lng: 27.644014 },
    { lat: 50.661309, lng: 27.002009 }
  ];

  const [showInfoIndex, setShowInfoIndex] = useState(false);
  const markers = [
    {
      position: { lat: 50.729878, lng: 28.027083 },
      icon: {
        url: explosion,
        size: { width: 50, height: 50 },
        scaledSize: { width: 50, height: 50 },
        anchor: { x: 25, y: 25 }
      },
      text: "Місце бою"
    },
    {
      position: { lat: 50.370079, lng: 28.294352 },
      icon: {
        url: explosion,
        size: { width: 50, height: 50 },
        scaledSize: { width: 50, height: 50 },
        anchor: { x: 25, y: 25 }
      },
      text: "Місце бою"
    },
    {
      position: { lat: 49.879526, lng: 29.291191 },
      text: "село Макарівка"
    }
  ]
  return (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 50.067847, lng: 28.656533 }}
    >
      <Polyline path={path} options={{ strokeColor: "#FF0000 " }} />
      {markers.map((marker, index) =>
        <Marker
          key={index}
          position={marker.position}
          icon={marker.icon}
          onClick={() => { setShowInfoIndex(index) }}
        >
          {showInfoIndex === index && <InfoWindow
            position={marker.position}
            onCloseClick={() => { setShowInfoIndex(false) }}
          >
            <div><h3>{marker.text}</h3></div>
          </InfoWindow>}
        </Marker>
      )}
    </GoogleMap>
  );
}

const MapComponent = withScriptjs(withGoogleMap(Map));

export default () => (
  <MapComponent
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCDir-wEYVogm1si98Sw234QFwEWWErjng&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `600px`, width: "100%" }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);