import React from 'react';
import { scroller } from 'react-scroll';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    paper: {
        background: 'black',
        color: 'white',
        fontSize: '26px',
        padding: '30px'
    }
});

const SideDrawer = (props) => {
    const styles = useStyles();
    const scrollToElement = (element) => {
        scroller.scrollTo(element, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -150
        });
        props.onClose(false);
    }
    return (
        <Drawer
            classes={{ paper: styles.paper }}
            anchor="right"
            open={props.open}
            onClose={() => props.onClose(false)}
        >
            <List component="nav">
                <ListItem button onClick={() => scrollToElement('featured')}>Головна</ListItem>
                <ListItem button onClick={() => scrollToElement('author')}>Про автора</ListItem>
                <ListItem button onClick={() => scrollToElement('book')}>Про книгу</ListItem>
                <ListItem button onClick={() => scrollToElement('read')}>Читати</ListItem>
                <ListItem button onClick={() => scrollToElement('location')}>Карта</ListItem>
            </List>
        </Drawer>
    );
};

export default SideDrawer;