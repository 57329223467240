import React from 'react';
import google_badge from '../../resources/images/icons/google-play-badge.png';
import book from '../../resources/images/icons/book.svg';
import epub from '../../resources/images/icons/epub.svg';
import pdf from '../../resources/images/icons/pdf.svg';

const Read = (props) => {
    return (
        <div className="read_wrapper">
            <div className="center_wrapper">
                <h2 className="section_title">Читати</h2>
                <div className="read_inner_wrapper">
                    <a href="https://books.apple.com/us/book/%D1%83-%D0%BB%D0%B0%D0%B1%D0%B5%D1%82%D0%B0%D1%85-%D0%B4%D0%BE%D0%BB%D1%96/id1488120714?mt=11&app=itunes" target="_blank" rel="noopener noreferrer" className="apple_btn"> </a>
                    <a href="https://play.google.com/store/books/details/%D0%92%D0%B0%D1%81%D0%B8%D0%BB%D1%8C_%D0%AF%D1%80%D0%B5%D0%BC%D1%87%D1%83%D0%BA_%D0%A3_%D0%BB%D0%B0%D0%B1%D0%B5%D1%82%D0%B0%D1%85_%D0%B4%D0%BE%D0%BB%D1%96?id=KQC5DwAAQBAJ" target="_blank" rel="noopener noreferrer" className="google_btn" style={{ backgroundImage: `url(${google_badge})` }}> </a>
                    <button onClick={props.toggle} target="_blank" rel="noopener noreferrer" className="read_btn" style={{ backgroundImage: `url(${book})` }}>Read now</button>
                    <a href="/book.epub" className="epub_btn" style={{ backgroundImage: `url(${epub})` }}>Get epub</a>
                    <a href="/book.pdf" className="epub_btn" target="_blank" rel="noopener noreferrer" style={{ backgroundImage: `url(${pdf})` }}>Get pdf</a>
                </div>
            </div>
        </div>
    );
};

export default Read;