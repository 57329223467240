import React from 'react';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import author from '../../resources/images/author.jpg';

const Author = () => {
    return (
        <div className="author_wrapper bck_black">
            <div className="center_wrapper">
                <h2 className="section_title white">Про автора</h2>
                <div className="author_inner_wrapper">
                    <Fade>
                        <div className="author_image">
                            <img src={author} alt="Василь Яремчук" />
                        </div>
                    </Fade>
                    <Slide right>
                        <div className="author_description">
                            <h3>Василь Яремчук</h3>
                            <p>Василь Яремчук – учасник УПА, фельдшер “диверсійних груп УПА”, свідок руйнації Києва “совєтським підпіллям”  восени 1941 року, каторжник Норильських концтаборів. Народився Василь Яремчук 6 червня 1922 року у селі Залізниця Корецького району Рівненської області.</p>
                            <p>Так як Волинь і Полісся у роки дитинства Василя Яремчука перебували під  польською окупацією, про школи з українською мовою навчання, вивчення історії України не могло бути й мови. Тому й навчався Василь Яремчук у сільській школі, яка була польською. Проте полякам не вдалося зламати дух українства у волинських школярів. На цих теренах виникла УПА.</p>
                        </div>
                    </Slide>
                </div>
            </div>
        </div>
    );
};

export default Author;