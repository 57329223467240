import React from 'react';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import book from '../../resources/images/book.jpg';

const Book = () => {
    return (
        <div className="book_wrapper bck_white">
            <div className="center_wrapper">
                <h2 className="section_title black">Про книгу</h2>
                <div className="book_inner_wrapper">
                    <Slide left>
                        <div className="book_description">
                            <h3>У лабетах долі</h3>
                            <p>Книга спогадів Василя Яремчука “У лабетах долі” друкується після його смерті. Автор усе життя носив у серці спогади про свою юність, про роки підпільної боротьби у рядах УПА, та найтяжчим тягарем були приховані від тодішньої совєтської громадськості згадки про жахливі роки в засланні у таборах Гулагу – у Норильську.</p>
                            <p>У цій книзі – життя без прикрас. Але вся розповідь проникнута не лише описами буття каторжан, а й великою любов’ю до свого народу, України, бажанням працювати на своїй вільній землі…</p>
                        </div>
                    </Slide>
                    <Fade>
                        <div className="book_image">
                            <img src={book} alt="У лабетах долі" />
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
};

export default Book;