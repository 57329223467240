import React from 'react';
import Video from './Video';

const Featured = () => {
    return (
        <div style={{ position: 'relative' }}>
            <Video />
        </div>
    );
};

export default Featured;