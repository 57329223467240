import React, { useState } from 'react';
import './resources/styles.css';
import { Element } from 'react-scroll';

import Header from './components/header_footer/Header';
import Featured from './components/Featured';
import Author from './components/Author';
import Book from './components/Book';
import Read from './components/Read';
import Reader from './components/Reader';
import Location from './components/Location';
import Footer from './components/header_footer/Footer';

const App = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="App">
      <Header />
      <Element name="featured">
        <Featured />
      </Element>
      <Element name="author">
        <Author />
      </Element>
      <Element name="book">
        <Book />
      </Element>
      <Element name="read">
        <Read toggle={() => setShowPopup(!showPopup)} />
        {showPopup ? <Reader toggle={() => setShowPopup(!showPopup)} /> : null}
      </Element>
      <Element name="location">
        <Location />
      </Element>
      <Footer />
    </div>
  );
}

export default App;
