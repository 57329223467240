import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import video from '../../resources/videos/video.mp4';
import videoWebm from '../../resources/videos/video.webm';

const Video = () => {
    const [height, setHeight] = useState(0);
    useEffect(() => {
        let playerHeight = document.getElementById('vjs_video_3_html5_api').offsetHeight;
        let windowHeight = window.innerHeight;
        if (playerHeight < windowHeight) {
            setHeight(playerHeight);
        } else {
            setHeight(windowHeight);
        }
    }, [height]);
    const videoJsOptions = {
        autoplay: true,
        playsinline: true,
        controls: false,
        loop: true,
        muted: true,
        sources: [
        {
            src: videoWebm,
            type: 'video/webm'
        },
        {
            src: video,
            type: 'video/mp4'
        }]
    }
    return (
        <div
            style={{
                height: `${height}px`,
                overflow: 'hidden'
            }}
        >
            <VideoPlayer {...videoJsOptions} />
        </div>
    );
};

export default Video;